//polyfills

if (!String.prototype.endsWith) {
	String.prototype.endsWith = function(search, this_len) {
		if (this_len === undefined || this_len > this.length) {
			this_len = this.length;
		}
		return this.substring(this_len - search.length, this_len) === search;
	};
}

if (typeof Object.assign != 'function') {
  Object.assign = function(target) {
    'use strict';
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    target = Object(target);
    for (var index = 1; index < arguments.length; index++) {
      var source = arguments[index];
      if (source != null) {
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}

// Production steps of ECMA-262, Edition 6, 22.1.2.1
if (!Array.from) {
  Array.from = (function () {
    var toStr = Object.prototype.toString;
    var isCallable = function (fn) {
      return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
    };
    var toInteger = function (value) {
      var number = Number(value);
      if (isNaN(number)) { return 0; }
      if (number === 0 || !isFinite(number)) { return number; }
      return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
    };
    var maxSafeInteger = Math.pow(2, 53) - 1;
    var toLength = function (value) {
      var len = toInteger(value);
      return Math.min(Math.max(len, 0), maxSafeInteger);
    };

    // The length property of the from method is 1.
    return function from(arrayLike/*, mapFn, thisArg */) {
      // 1. Let C be the this value.
      var C = this;

      // 2. Let items be ToObject(arrayLike).
      var items = Object(arrayLike);

      // 3. ReturnIfAbrupt(items).
      if (arrayLike == null) {
        throw new TypeError('Array.from requires an array-like object - not null or undefined');
      }

      // 4. If mapfn is undefined, then let mapping be false.
      var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
      var T;
      if (typeof mapFn !== 'undefined') {
        // 5. else
        // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
        if (!isCallable(mapFn)) {
          throw new TypeError('Array.from: when provided, the second argument must be a function');
        }

        // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
        if (arguments.length > 2) {
          T = arguments[2];
        }
      }

      // 10. Let lenValue be Get(items, "length").
      // 11. Let len be ToLength(lenValue).
      var len = toLength(items.length);

      // 13. If IsConstructor(C) is true, then
      // 13. a. Let A be the result of calling the [[Construct]] internal method
      // of C with an argument list containing the single item len.
      // 14. a. Else, Let A be ArrayCreate(len).
      var A = isCallable(C) ? Object(new C(len)) : new Array(len);

      // 16. Let k be 0.
      var k = 0;
      // 17. Repeat, while k < len… (also steps a - h)
      var kValue;
      while (k < len) {
        kValue = items[k];
        if (mapFn) {
          A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
        } else {
          A[k] = kValue;
        }
        k += 1;
      }
      // 18. Let putStatus be Put(A, "length", len, true).
      A.length = len;
      // 20. Return A.
      return A;
    };
  }());
}

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function(predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
    configurable: true,
    writable: true
  });
}

if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

require('intersection-observer');

import Vue from 'vue';
import Vuelidate from 'vuelidate';
import Components from "./components";
import simpleParallax from 'simple-parallax-js';
import SmoothScroll from 'smooth-scroll';
import inView from 'in-view';
import Headroom from "headroom.js";
import { EventBus } from './event-bus.js';
import 'lazysizes';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import Swiper from 'swiper';
import Cookies from 'js-cookie';
import objectFitImages from 'object-fit-images';

import * as scrll from './funcs.js';
Vue.use(Vuelidate);

var homeVideo = document.querySelector('video');
objectFitImages(homeVideo);

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  data: {
    showNav: false,
    showModal: false
  },
  mounted: function() {
    EventBus.$on('menu-toggle', toggle => {
      this.showNav = toggle;
    });
    EventBus.$on('modal-toggle', toggle => {
      this.showModal = !this.showModal
    });
    window.addEventListener('keyup', (event) => {
      if (event.keyCode == 27) {
        if(this.showModal){
          this.showModal = false;
        }
      }
    });
  },
  watch: {
    showNav: function () {
      const el = document.body;
      if(this.showNav){
        el.classList.add('l_no-scroll');
      } else {
        el.classList.remove('l_no-scroll');
      }
      this.isVisible = this.isVisibleProp
    },
    showModal: function () {
      const el = document.body;
      if(this.showModal){
        el.classList.add('l_no-scroll');
      } else {
        el.classList.remove('l_no-scroll');
      }
    }
  },
});

//* parallax ===== */

var image = document.getElementsByClassName('l_parallax');
new simpleParallax(image, {
  overflow: true,
  transition: 'cubic-bezier(0,0,0,1)',
  breakpoint: 767
});

//* smooth scroll ==== */

var scroll = new SmoothScroll('.js-scroll', {
  header: 'header'
});

//* heading pinning === */

if ("undefined" !== typeof Headroom) {
  var headroom = new Headroom(
    document.getElementById("js-subnav-holder"),
    {
      offset: 10,
      tolerance: {
        up: 0,
        down: 5
      },
      onUnpin : function() {
        $('header').addClass('s_unpinned');
      },
      onPin : function() {
        $('header').removeClass('s_unpinned');
      },
    }
  );
  headroom.init();
}

//* home video fade out ==== */

inView('.l_video-fade')
    .once('enter', el => {
      var video = document.querySelectorAll(".l_video-fade > video")[0];
      var image = document.querySelectorAll(".l_video-fade > div > picture > img")[0];
      var heading = document.querySelectorAll(".l_video-fade > .hero-holder h2")[0];
      if(video){
        video.oncanplaythrough = (event) => {
          image.classList.add('s_hidden');
          heading.classList.add('s_fade-in-slide-up');
          video.play();
        };
    }
    })
    .once('exit', el => {
        // el.style.opacity = 0.5;
    });

//* background image lazy loading === */

document.addEventListener('lazybeforeunveil', function(e){
  var bg = e.target.getAttribute('data-bg');
  if(bg){
      e.target.style.backgroundImage = 'url(' + bg + ')';
  }
});

 //* main menu on scroll === */

 var timeout;
    // Listen for resize events
    window.addEventListener('scroll', function (event) {
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }
      timeout = window.requestAnimationFrame(function () {
        var scroll = $(window).scrollTop();
        if (scroll > 20) {
          $('header').addClass("s_scrolled");
        } else {
          $('header').removeClass("s_scrolled")
        }
    });
  });

// foreach swiper, get loopedSlides (amount of slides) and attribute it as
var productSwipers = document.querySelectorAll('.pak-swiper-container');
if(productSwipers.length){
  productSwipers.forEach(function(el){
    var swiper = new Swiper(el, {
      slidesPerView: 'auto',
      centeredSlides: true,
      watchSlidesVisibility: true,
      loop: true,
      loopedSlides: el.dataset.slideLength,
      spaceBetween: 10,
      preloadImages: true,
      updateOnImagesReady: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      // breakpoints: {
      //   300: {
      //     spaceBetween: 20
      //   },
      //   600: {
      //     spaceBetween: 20
      //   },
      //   768: {
      //     spaceBetween: 50
      //   },
      // },
      on: {
        // imagesReady: function () {
          // if (typeof swiper !== "undefined"){
          //   swiper.slideToLoop(0, 0);
          // }
        // },
      },
    });
    swiper.on('imagesReady', function() {
      swiper.update();
      swiper.slideToLoop(0, 0);
     });
  });
}

// swiperjs


//* products

var productSwitcher = document.querySelector('.product-switcher');
if(productSwitcher){
  productSwitcher.addEventListener('change', function(event){
      window.location.href = event.target.value;
  });
}

//* is touch device =================================================== */

var isTouch = false;
if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
  isTouch = true;
}


//* breakpoints =================================================== */

function breakpointWidth(bp) {
  bpWidth = 768;
  if (bp == 'baby') {
    bpWidth = 479;
  }
  if (bp == 'teen') {
    bpWidth = 600;
  }
  if (bp == 'mama') {
    bpWidth = 768;
  }
  if (bp == 'mega') {
    bpWidth = 1279;
  }
  return bpWidth;
}

// function breakpointMax(bp) {
//   bpWidth = breakpointWidth(bp);
//   if ($(window).width() <= bpWidth) {
//     return true;
//   } else {
//     return false;
//   }
//   return true;
// }

// function breakpointMin(bp) {
//   bpWidth = breakpointWidth(bp);
//   if ($(window).width() > bpWidth) {
//     return true;
//   } else {
//     return false;
//   }
//   return true;
// }

var sitewideBarClose = document.querySelector('.sitewide-bar-close');
var sitewideBar = document.querySelector('.sitewide-bar-wrapper');

if(sitewideBar && !Cookies.get('closedSitewideBar')){
  sitewideBar.classList.add('s_active');
}

if(sitewideBarClose){
  sitewideBarClose.addEventListener('click', function(e){
    // set the cookie
    Cookies.set('closedSitewideBar', true);
    // close the bar
    var sitewideBar = document.querySelector('.sitewide-bar-wrapper');
    sitewideBar.classList.remove('s_active');
});
}


//* google rating =================================================== */

// $(function(){

//   var starIcon = '<svg class="icon"><use href="#star"></use></svg>';
//   var starHalfIcon = '<svg class="icon"><use href="#star-half"></use></svg>';

//   $.getJSON({
//     url: '/siteModule/fetchGoogleRating',
//     cache: true,
//     success: function(data, textStatus) {
//       var rating = data.rating;
//       var stars = Math.round(rating*2)/2;
//       var starIcons = '';
//       for (var i = 1; i <= stars; i++) {
//         starIcons += starIcon;
//       }
//       if (stars % 1 != 0) {
//         starIcons += starHalfIcon;
//       }
//       $('.googlerating-rating').text(rating);
//       $('.googlerating-stars').html(starIcons);
//       $('.googlerating').addClass('s_active');
//     },
//     error: function(response, textStatus, errorThrown){
//       $('.googlerating').remove();
//     },
//   });

// });

var videoImage = document.querySelector('.videoBlock-embed-image');
if(videoImage){
  videoImage.addEventListener('click', function(e){
    var videoParent = videoImage.closest('.videoBlock-embed');
    var videoIframe = videoParent.querySelector('iframe');
    videoIframe.src += "?autoplay=1";
    videoImage.style.visibility = 'hidden';
  })
}